import { Identifier } from "../models";
import { IdentifierMetAfkorting } from "../models/identifier";
import { StringSanitizer } from "./StringSanitizer";

const col = require("../assets/waardelijsten/rwc_caribische_openbare_lichamen_compleet_1.json");
const gemeenten = require("../assets/waardelijsten/rwc_gemeenten_compleet_4.json");
const ministeries = require("../assets/waardelijsten/rwc_ministeries_compleet_1.json");
const overige = require("../assets/waardelijsten/rwc_overige_overheidsorganisaties_compleet_3.json");
const provincies = require("../assets/waardelijsten/rwc_provincies_compleet_1.json");
const waterschappen = require("../assets/waardelijsten/rwc_waterschappen_compleet_1.json");

const stringSanitizer = new StringSanitizer();

export class WaardelijstCrawler {

    public static searchOrganisationLabel = (organisationCode: string) => {
        organisationCode = stringSanitizer.extractIdentifierFromPid(organisationCode) ?? organisationCode;
        const waardelijst = this.findWaardelijst(organisationCode);

        if(waardelijst)
            return this.searchWaardelijstLabel(waardelijst, organisationCode);

        return organisationCode;
    }

    public static searchOrganisationAfkorting = (organisationCode: Identifier) => {
        const orgId:string = stringSanitizer.extractIdentifierFromPid(organisationCode.id) ?? organisationCode.id;
        organisationCode = {id:orgId , label:organisationCode.label};
        const waardelijst = this.findWaardelijst(organisationCode);

        if(waardelijst)
            return this.searchWaardelijstAfkorting(waardelijst, organisationCode);

        return organisationCode as IdentifierMetAfkorting;
    }

    private static findWaardelijst = (organisationCode: Identifier | string) => {
        const code = typeof organisationCode === 'string' || organisationCode instanceof String ? organisationCode.replace(/\d/g, '') : organisationCode.id.replace(/\d/g, '');
        
        switch (code) {
            case "col": return col;
            case "gm": return gemeenten;
            case "mnre": return ministeries;
            case "oorg": return overige;
            case "pv": return provincies;
            case "ws": return waterschappen;
            default: return ministeries;
        }
    }

    private static searchWaardelijstLabel = (waardelijst: any, organisationCode: string) => {
        const organisation = waardelijst.find((org: any) => org["@id"].includes(organisationCode));

        const id = organisation["@id"];
        const label = organisation["http://www.w3.org/2000/01/rdf-schema#label"][0]["@value"];

        return { id, label };
    }

    private static searchWaardelijstAfkorting = (waardelijst: any, organisationCode: Identifier) => {
        const organisation = waardelijst.find((org: any) => organisationCode.label ? org["http://www.w3.org/2000/01/rdf-schema#label"][0]["@value"] === organisationCode.label : org["@id"].includes(organisationCode.id));

        if(!organisation){
            console.log("Error: searchWaardelijstAfkorting organisatie niet gevonden. Organisatie: " + organisationCode.id + " - " + organisationCode.label );
            return organisationCode as IdentifierMetAfkorting;
        }

        const id = organisation["@id"];
        const label = organisation["http://www.w3.org/2000/01/rdf-schema#label"][0]["@value"];
        const afkorting = organisation["https://identifier.overheid.nl/tooi/def/ont/afkorting"][0]["@value"];

        return { id, label, afkorting } as IdentifierMetAfkorting;
    }
}