import { useEffect, useRef, useState } from "react";
import useScrollbarSize from "react-scrollbar-size";
import AutoSizer from "react-virtualized-auto-sizer";
import { ListOnScrollProps, VariableSizeList } from "react-window";
import { SelectedRow } from "../../../models/search-result";
import { ITableRow } from "../../interfaces/TableRows";
import styles from "./styles.module.scss";

interface Props {
  theads: (JSX.Element | string)[];
  rows: ITableRow[];
  selectedRows?: SelectedRow[];
  theadsHighlightIndex?: number;
  theadsHighlighticon?: string;
  columnWidth?: string[];
  OnClickTheads?: (index:number) => void;
  OnEndOfScroll?: () => void;
}

export function WindowTableAtom(props: Props) {
  const listRef = useRef<any>();
  const defaultRowHeight = 82;
  const { height, width } = useScrollbarSize();
  const [selectedRows, setSelectedRows] = useState<SelectedRow[]|undefined>(undefined);
  const [theadsHighlightIndex, setTheadsHighlightIndex] = useState<number|undefined>(undefined);
  const [theadsHighlighticon, setTheadsHighlighticon] = useState<number|undefined>(undefined);
  
  const hasSelectedRow = () => {
    return selectedRows && selectedRows.length > 0;
  }

  const hasHeadersHighlight = () => {
    return props.theadsHighlightIndex !== theadsHighlightIndex || props.theadsHighlighticon !== theadsHighlighticon;
  }

  useEffect(()=>{
    if(props.selectedRows !== selectedRows){
      selectedRows?.forEach((row:SelectedRow) => toggleSize(row.index));
      setSelectedRows(props.selectedRows);
    }

    if(props.theadsHighlightIndex === undefined && hasSelectedRow() && hasHeadersHighlight()){
      selectedRows?.forEach((row:SelectedRow) => toggleSize(row.index));
      if(props.theadsHighlightIndex !== theadsHighlightIndex)
        setTheadsHighlightIndex(props.theadsHighlightIndex);
      else
        setTheadsHighlighticon(props.theadsHighlightIndex);
    }
  },[props.selectedRows, props.theadsHighlightIndex, props.theadsHighlighticon]);

  const inlineStyle = (columnWidth: number |string, highlight:boolean):React.CSSProperties => {
    let properties:React.CSSProperties = {};

    if(columnWidth)
      properties.width = columnWidth;

    if(highlight)
      properties.backgroundColor = "#154273";
    
    if(highlight && props.theadsHighlighticon){
      properties.backgroundImage = `url(/assets/icons/${props.theadsHighlighticon})`;
      properties.backgroundRepeat = "no-repeat";
      properties.backgroundPosition = "right 0.65em bottom 0.65em";
      properties.backgroundSize = "1.2em";
    }

    return properties;
  }

  const renderHeader = () => {
    let className = styles.header;
    let padding;

    padding = {paddingRight: width};
     
    return (
      <div id={"headers"} className={className} style={padding}>
        {props.theads.map((item: (JSX.Element | string), i: number) => (
          <div
            key={"th " + i}
            className={styles.header_cell}
            onClick={()=>props.OnClickTheads!(i) }
            style={inlineStyle(props.columnWidth && i <= props.columnWidth.length ? props.columnWidth[i]: "auto", props.theadsHighlightIndex === i)}
          >
            <div className={styles.item}>{item}</div>
          </div>
        ))}
      </div>
    )
  };

  const RenderRow = ({ index, style }: { index: number; style: React.CSSProperties; }): JSX.Element => {
    const styleOddOrEven = styles[`${index % 2 ? "ListItemOdd" : "ListItemEven"}`];
    return (
      <div className={`${styleOddOrEven}`} style={style} onClick={async () => { if(props.rows[index].onClick !== undefined) {props.rows[index].onClick!(); toggleSize(index); }} }>
        <div className={styles.row + (getSize(index) > defaultRowHeight ? ` ${styles.lightBottomBorder}` : "")}>
          {props.rows[index].content?.map((item:any, i:number) => (
            <div
              key={"row_" + index + "cell_" + i}
              className={styles.cell}
              style={inlineStyle(props.columnWidth && i <= props.columnWidth.length ? props.columnWidth[i] : "auto", false)}
            >
              <div className={styles.item}>{item}</div>
            </div>
          ))}
        </div>
        {getSize(index) > defaultRowHeight && props.rows[index].foldableContent!.content !== undefined && props.rows[index].foldableContent!.content.length > 0 &&
          props.rows[index].foldableContent!.content.map((item:any, i:number) => (
            <div key={"bijlage_row_" + index + "cell_" + i } className={styles.row + ` ${styles.lightBottomBorder}`}>
              {item.map((item2: any, i2: number) => (
                <div
                  key={"row_" + index + "cell_" + i + "." + i2}
                  className={styles.cell}
                  style={inlineStyle(props.columnWidth && i2 <= props.columnWidth.length ? props.columnWidth[i2] : "auto", false)}
                >
                  {/* {i2 === 0 && <SpacerAtom space={4} type="horizontal"/>} */}
                  {<div className={styles.item}>{item2}</div>}
                </div>
              ))}
            </div>
          ))
        }
      </div>
    );
  };

  const toggleSize = (i:number) => {
    if (listRef.current) {
      listRef.current.resetAfterIndex(i);
    }
  };

  const getSize = (i:number) => {
    let size = defaultRowHeight;   
    if(props.selectedRows?.find((row:SelectedRow) => row.index === i)){
      size = defaultRowHeight * (props.rows[i].foldableContent && props.rows[i].foldableContent?.content && props.rows[i].foldableContent!.content.length > 0? props.rows[i].foldableContent!.content.length  + 1: 1);
    }

    return size;
  };

  return (
    <AutoSizer>
       {({height, width}: {height:number, width:number}) => (
        <div className={styles.container} style={({width: width + 1})}>
          {renderHeader()}
          <VariableSizeList 
            ref={listRef}    
            className={styles.List}
            itemCount={props.rows.length}
            itemSize={getSize}
            height={height - 160}
            width={width}
            onScroll={(scroll: ListOnScrollProps) => {
              if (props.OnEndOfScroll && (props.rows.length * defaultRowHeight) - scroll.scrollOffset < 10 + height - 160){
                props.OnEndOfScroll();
              }
            }}
          >
            {rowProps => <RenderRow {...rowProps}  />}
          </VariableSizeList>    
        </div>
      )}
    </AutoSizer>
  );
}
