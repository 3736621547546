import { ResponseCodesFactory } from "../factories";
import { Controllers } from "../models";
import { StringSanitizer } from "../utils/StringSanitizer";

export abstract class BaseClient {
    protected readonly _apiEndpoint: string;
    private readonly _sanitizer: StringSanitizer;
    private readonly _headers: Headers;
    private readonly _responseFactory: ResponseCodesFactory;

    constructor(defaultRequestHeaders: Headers, apiEndpoint: string) {
        this._sanitizer = new StringSanitizer();
        this._responseFactory = new ResponseCodesFactory();

        this._apiEndpoint = apiEndpoint;
        this._headers = defaultRequestHeaders;
    }

    protected api = async <T>(controller: Controllers, id?: string, method?: 'GET' | 'POST' | 'PUT' | 'DELETE', body?: any, queryParams?: string, toevoeging?: string, headers?: {key:string; value:string;}[]) => {
        const sanitizedUrl = this._sanitizer.sanitizeApiEndpoint(this._apiEndpoint, controller, id, queryParams, toevoeging);
        const json = body ? JSON.stringify(body) : undefined;

        if (headers) {
            headers.forEach((header:{key: string; value: string;}) => this._headers.append(header.key, header.value ?? ""));
        }

        let response:Response = new Response();
        let count:number = 5;
        while(count > 0) {
            try {
                const fetchCall = await fetch(sanitizedUrl, {
                    credentials: 'include',
                    method: method ?? 'GET',
                    body: json,
                    headers: this._headers
                });

                if(!fetchCall.ok)
                    response = new Response(fetchCall.body ?? undefined, { status: fetchCall.status ?? 418, statusText: fetchCall.statusText ?? ""});
                else
                    return this.handleResponse<T>(controller, fetchCall);
                    
            } catch (e:any) {
                if(!response.status || response.status === 200)
                    response = new Response(e.body, { status: e.status ?? 418, statusText: e.message ?? ""});
            }
            count--;
            await new Promise(resolve => setTimeout(resolve, 500));
        }
        const newResponse = new Response(response?.body ?? undefined, { status: response?.status ?? 418, statusText: response?.statusText ?? ""});
        return this.handleResponse<T>(controller, newResponse);
    }

    protected handleResponse = async <T>(controller: Controllers, response: Response): Promise<{ response: Response, json?: T, headers: Headers }> => {
        console.log('=====> RESPONSE', response);
        console.log('=====> HEADERS', response.headers);
        if (!response.ok){
            let error = new Error(this._responseFactory.create(controller, response.status));
            throw error;
        }

        const contentType = response.headers.get("content-type");

        console.log("=========> All Headers Keys");
        for (let entry in response.headers.keys())
            console.log(entry);
        console.log("=========> All Headers Keys");

        console.log("=========> All Headers Entries");

        for (let entry in response.headers.entries())
            console.log(entry);

        console.log("=========> All Headers Entries");


        if (!contentType)
            return { response: response, headers: response.headers };

        const isJsonContent = (contentType.indexOf("application/json") !== -1 || contentType.indexOf("application/hal+json") !== -1);

        if (contentType && isJsonContent) {
            return {
                json: await response.json(),
                headers: response.headers,
                response: response
            };
        }

        return {
            response: response,
            headers: response.headers
        }
    }

}