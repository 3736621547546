import { Metadata } from '../models';
import { SearchResult } from '../models/search-result';
import { StringSanitizer } from '../utils/StringSanitizer';
import { BaseClient } from './baseClient';

const stringSanitizer = new StringSanitizer();

export interface IMetadataApi {
  createMetadataSession(
    meta: Metadata
  ): Promise<{ uploadUrl: string; pid: string }>;
  updateMetadata(meta: Metadata, id: string): Promise<Metadata>;
  fetchMetadata(id: string): Promise<Metadata>;
  getDocumentBijlages(id: string): Promise<SearchResult[]>;
}

export const mockHoofdDoc = {
  document: {
    creatiedatum: '2022-09-11T12:35:46.43498474Z',
    pid: 'https://open.overheid.nl/documenten/9fa5e4b7-23ca-487b-9e26-8795b7657eaa',
    identifiers: ['DOC100323 - 1325'],
    publisher: {
      id: 'https://identifier.overheid.nl/tooi/id/ministerie/mnre1034',
      label: 'ministerie van Binnenlandse Zaken en Koninkrijksrelaties',
    },
    language: {
      id: 'http://publications.europa.eu/resource/authority/language/NLD',
      label: 'Nederlands',
    },
    omschrijvingen: ['Omschrijving'],
    titelcollectie: {
      officieleTitel: 'DOC100323 - 1325ftrft',
    },
    classificatiecollectie: {
      documentsoorten: [
        {
          id: 'https://identifier.overheid.nl/tooi/def/thes/kern/c_386e74cb',
          label: 'convenant',
        },
        {
          id: 'https://identifier.overheid.nl/tooi/def/thes/kern/c_0d0e9b52',
          label: 'nader rapport',
        },
      ],
      themas: [
        {
          id: 'https://identifier.overheid.nl/tooi/def/thes/top/c_2408fb5a',
          label: 'cultureel erfgoed',
        },
      ],
    },
    documenthandelingen: [
      {
        soortHandeling: {
          id: 'https://identifier.overheid.nl/tooi/def/thes/kern/c_641ecd76',
          label: 'vaststelling',
        },
        atTime: '2023-03-10T12:25:25.386Z',
        wasAssociatedWith: {
          id: 'https://identifier.overheid.nl/tooi/id/ministerie/mnre1034',
          label: 'ministerie van Binnenlandse Zaken en Koninkrijksrelaties',
        },
      },
      {
        soortHandeling: {
          id: 'https://identifier.overheid.nl/tooi/def/thes/kern/c_641ecd76',
          label: 'vaststelling',
        },
        atTime: '2023-04-10T12:25:25.386Z',
        wasAssociatedWith: {
          id: 'https://identifier.overheid.nl/tooi/id/ministerie/mnre1034',
          label: 'ministerie van Binnenlandse Zaken en Koninkrijksrelaties',
        },
      },
    ],
    publicatiebestemming: ['rijksoverheid.nl'],
    zichtbaarheidsdatumtijd: "9999-12-31T12:00:00.000Z",
  },
  plooiIntern: {
    publicatiestatus: 'latere-publicatie',
    dcnId: 'plooi-api-9fa5e4b7-23ca-487b-9e26-8795b7657eaa',
    sourceLabel: 'plooi-api',
  },
  documentrelaties: [
    {
      relation:
        'https://open.overheid.nl/documenten/ce3c68b6-c68d-45c0-ab43-123f6c920dc7',
      // "role": "https://identifier.overheid.nl/tooi/def/thes/kern/c_4d1ea9ba"
      role: 'https://identifier.overheid.nl/tooi/def/thes/kern/c_05f4a5f3',
    },
  ],
  versies: [
    {
      openbaarmakingsdatum: '2023-03-10',
      wijzigingsdatum: '2023-03-10',
      mutatiedatumtijd: '2023-03-10T12:42:44.215415468Z',
      bestanden: [
        {
          bestandsnaam: 'metadata.json',
          label: 'metadata',
        },
        {
          bestandsnaam: 'BestandsnaamHoofdDoc.pdf',
          label: 'file',
        },
      ],
    },
  ],
};

export const mockBijlageDocument = {
  document: {
    zichtbaarheidsdatumtijd: '2023-09-11T12:35:46.43498474Z',
    creatiedatum: '2022-09-11T12:35:46.43498474Z',
    pid: 'https://open.overheid.nl/documenten/ce3c68b6-c68d-45c0-ab43-123f6c920dc7',
    identifiers: ['Bijlage100323 - 1330'],
    publisher: {
      id: 'https://identifier.overheid.nl/tooi/id/ministerie/mnre1034',
      label: 'ministerie van Binnenlandse Zaken en Koninkrijksrelaties',
    },
    language: {
      id: 'http://publications.europa.eu/resource/authority/language/NLD',
      label: 'Nederlands',
    },
    publicatiebestemming: ['rijksoverheid.nl'],
    omschrijvingen: ['Omschrijving'],
    titelcollectie: { officieleTitel: 'Bijlage100323 - 1330' },
    classificatiecollectie: {
      documentsoorten: [
        {
          id: 'https://identifier.overheid.nl/tooi/def/thes/kern/c_386e74cb',
          label: 'convenant',
        },
      ],
      themas: [
        {
          id: 'https://identifier.overheid.nl/tooi/def/thes/top/c_2408fb5a',
          label: 'cultureel erfgoed',
        },
      ],
    },
    documenthandelingen: [
      {
        soortHandeling: {
          id: 'https://identifier.overheid.nl/tooi/def/thes/kern/c_641ecd76',
          label: 'vaststelling',
        },
        atTime: '2023-03-10T12:30:16.803Z',
        wasAssociatedWith: {
          id: 'https://identifier.overheid.nl/tooi/id/ministerie/mnre1034',
          label: 'ministerie van Binnenlandse Zaken en Koninkrijksrelaties',
        },
      },
    ],
  },
  plooiIntern: {
    publicatiestatus: 'gepubliceerd',
    dcnId: 'plooi-api-ce3c68b6-c68d-45c0-ab43-123f6c920dc7',
    sourceLabel: 'plooi-api',
  },
  documentrelaties: [
    {
      relation:
        'https://open.overheid.nl/documenten/9fa5e4b7-23ca-487b-9e26-8795b7657eaa',
      role: 'https://identifier.overheid.nl/tooi/def/thes/kern/c_4d1ea9ba',
    },
  ],
  versies: [
    {
      openbaarmakingsdatum: '2023-03-10',
      wijzigingsdatum: '2023-03-10',
      mutatiedatumtijd: '2023-03-10T12:35:46.43498474Z',
      bestanden: [
        {
          bestandsnaam: 'metadata.json',
          label: 'metadata',
        },
        {
          bestandsnaam: 'Bestandsnaam.pdf',
          label: 'file',
        },
      ],
    },
    {
      openbaarmakingsdatum: '2023-03-10',
      wijzigingsdatum: '2023-03-10',
      mutatiedatumtijd: '2023-03-10T12:35:46.43498474Z',
      bestanden: [
        {
          bestandsnaam: 'metadata.json',
          label: 'metadata',
        },
        {
          bestandsnaam: 'Bestandsnaam.xml',
          label: 'xml',
        },
      ],
    },
  ],
};

export const mockSearchResult: SearchResult = {
  document: {
    pid: 'https://open.overheid.nl/documenten/d059e50f-8a49-45cd-b5a7-c12ca0a31ef0',
    creatiedatum: '2023-10-04T12:00:00Z',
    publisher: {
      id: 'https://identifier.overheid.nl/tooi/id/ministerie/mnre1034',
      label: 'ministerie van Binnenlandse Zaken en Koninkrijksrelaties',
    },

    opsteller: {
      id: 'https://identifier.overheid.nl/tooi/id/organisatie_onbekend',
      label: 'Onbekend',
    },
    identifiers: ['Identificatiekenmerk'],
    language: {
      id: 'http://publications.europa.eu/resource/authority/language/NLD',
      label: 'Nederlands',
    },
    titelcollectie: {
      officieleTitel: 'Bijlage1696409672299',
    },
    classificatiecollectie: {
      documentsoorten: [
        {
          id: 'https://identifier.overheid.nl/tooi/def/thes/kern/c_2977c34f',
          label: 'beslisnota',
        },
      ],
      themas: [
        {
          id: 'https://identifier.overheid.nl/tooi/id/thema_onbekend',
          label: 'Onbekend',
        },
      ],
    },
    documenthandelingen: [
      {
        soortHandeling: {
          id: 'https://identifier.overheid.nl/tooi/def/thes/kern/c_dfcee535',
          label: 'ontvangst',
        },
        atTime: '2023-10-04T08:54:35.494Z',
        wasAssociatedWith: {
          id: 'https://identifier.overheid.nl/tooi/id/ministerie/mnre1010',
        },
      },
    ],
    publicatiebestemming: ['rijksoverheid.nl'],
    geldigheid: {
      begindatum: '2023-10-04T12:00:00Z',
    },
  },
  versies: [
    {
      mutatiedatumtijd: '2023-10-04T08:54:39.8445618Z',
      openbaarmakingsdatum: '2023-10-04',
      nummer: 1,
      oorzaak: 'oorzaak',
      redenVerwijderingVervanging: 'redenVerwijderingVervanging',
    },
  ],
  plooiIntern: {
    publicatiestatus: 'gepubliceerd',
    dcnId: 'dcnId',
    aanbieder: 'aanbieder',
    extId: ['exId'],
    sourceLabel: 'sourceLabel',
  },
  documentrelaties: [
    {
      relation:
        'https://open.overheid.nl/documenten/56f6de8e-dfc7-401e-bd91-a5bac611d6b7',
      role: 'https://identifier.overheid.nl/tooi/def/thes/kern/c_4d1ea9ba',
    },
  ],
};

function safeParse<T>(jsonString: string): T {
  return JSON.parse(jsonString) as T;
}

export class MockMetadataApi implements IMetadataApi {
  createMetadataSession(
    meta: Metadata
  ): Promise<{ uploadUrl: string; pid: string; sessionId: string }> {
    return Promise.resolve({
      uploadUrl: 'https://uploadmetadocument.com',
      pid: '123',
      sessionId: '123',
    });
  }
  updateMetadata(meta: Metadata, id: string): Promise<Metadata> {
    return Promise.resolve(
      safeParse<Metadata>(JSON.stringify(mockBijlageDocument))
    );
  }
  fetchMetadata(id: string): Promise<Metadata> {
    if (
      id === stringSanitizer.extractIdentifierFromPid(mockHoofdDoc.document.pid)
    ) {
      return Promise.resolve(mockHoofdDoc);
    }

    return Promise.resolve(mockBijlageDocument);
  }
  getDocumentBijlages(id: string): Promise<SearchResult[]> {
    return new Promise((resolveOuter) => {
      resolveOuter(
        new Promise((resolveInner) => {
          setTimeout(resolveInner, 500);
        })
      );
    }).then((_) => [mockSearchResult, mockSearchResult] as SearchResult[]);
  }
}

export class MetadataApi extends BaseClient implements IMetadataApi {
  constructor() {
    super(
      new Headers({
        'Content-type': 'application/json',
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        Pragma: 'no-cache',
        Expires: '0',
      }),
      process.env.REACT_APP_METADATA_API_ENDPOINT as string
    );
  }

  //Location header will be returned for upload
  createMetadataSession = async (
    meta: Metadata
  ): Promise<{ uploadUrl: string; pid: string; }> => {
    let body: Metadata = {
      document: meta.document,
      documentrelaties: meta.documentrelaties ?? [],
    };

    const { json, headers } = await this.api<Metadata>(
      'metadata',
      undefined,
      'POST',
      body
    );

    if (!json) throw new Error('error');

    const pid = json.document.pid as string;
    const pidComonents = (json.document.pid as string).split('/');
    const id = pidComonents[pidComonents.length - 1];


    return {
      uploadUrl: `${process.env.REACT_APP_DOCUMENT_API_ENDPOINT}/documenten/${id}`,
      pid,
    };
  };

  updateMetadata = async (meta: Metadata, id: string) => {
    let body: Metadata = {
      document: meta.document,
      documentrelaties: meta.documentrelaties ?? [],
    };

    const { json } = await this.api<Metadata>(
      'metadata',
      id,
      'PUT',
      body,
      undefined
    );

    if (!json) throw new Error('Error');

    return json;
  };

  fetchMetadata = async (id: string) => {
    const { json } = await this.api<Metadata>('metadata', id, 'GET');

    if (!json) throw new Error('Error');

    return json as Metadata;
  };

  getDocumentBijlages = async (id: string) => {
    const { json } = await this.api<Metadata[]>(
      'metadata',
      id,
      'GET',
      undefined,
      undefined,
      '/bijlagen'
    );

    if (!json) throw new Error('Error');

    return json as SearchResult[];
  };
}
