import { useCallback, useEffect, useRef, useState } from "react";
import { CheckboxListMolecule, IconLabelMolecule } from "..";
import { Identifier } from "../../../models";
import { IListValidator } from "../../../models/validator";
import { DetectClickArea } from "../../../utils/Clickable";
import { filterBySearch } from "../../../utils/FilterList";
import { StringSanitizer } from "../../../utils/StringSanitizer";
import { ChipAtom, TextInputAtom } from "../../atoms";
import { getSelectedItemsFromCategoryList, ICategoryList } from "../../interfaces/CategoryList";
import { ICheckbox } from "../../interfaces/Checkbox";
import { LabelMolecule } from "../label";
import styles from "./styles.module.scss";

const stringSanitizer = new StringSanitizer();

interface Props {
  tekst: {
    id: string,
    label: string,
    placeholder?: string,
    tooltip?: string[],
  },
  categories: ICategoryList[];
  selected: Identifier[],
  required?: boolean;
  disabled?: boolean;
  singleSelect?: boolean;
  search?: boolean;
  validations?: IListValidator[];
  inputRef?: (ref: HTMLInputElement) => void;
  onUpdated: (id: Identifier) => void;
}

export function SelectDropdownMolecule(props: Props) {
  const [showValidations, setShowValidationMessage] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const containerRef = useRef<HTMLElement>();

  const selectedItemsList = getSelectedItemsFromCategoryList(props.categories);
  const validationErrors = props.validations?.map(validate => (selectedItemsList.length > 0 ? validate.validate(selectedItemsList) : validate.validate(props.selected)) ?? "").filter(err => err.length > 0) ?? [];
  const showValidationError = validationErrors.length > 0 && showValidations && !props.disabled;
  let classNameControls = styles.controls;
  let classNameInput = styles.select;
  let classNameResults = styles.results;

  if (selectedItemsList.length > 0 || props.selected.length > 0){
    const styleSelected = styles[`controls--selected`];
    classNameControls += ` ${styleSelected}`;
  }

  if (props.disabled) {
    const styleDisabled = styles[`container--disabled`];
    classNameInput += ` ${styleDisabled}`;
  }
  else if (expanded) {
    const styleExpanded = styles[`select--expand`];
    classNameInput += ` ${styleExpanded}`;
  }

  if (expanded) {
    const styleExpanded = styles[`results--expand`];
    classNameResults += ` ${styleExpanded}`;
  }

  if (showValidationError) {
    const styleInputAlert = styles[`select--alert`];
    classNameInput += ` ${styleInputAlert}`;
    const styleResultAlert = styles[`results--alert`];
    classNameResults += ` ${styleResultAlert}`;
  }

  const collapse = () => {
    if(expanded) {
      setExpanded(false);
      setShowValidationMessage(true);
    }
  }

  const onClick = (cb: ICheckbox | Identifier) => {
    props.onUpdated({ id: stringSanitizer.extractIdentifier(`${props.tekst.id}_${cb.id}`), label: cb.label });
    props.singleSelect && collapse();
    setSearchTerm("");
  };

  const detectClickArea = useCallback((event: MouseEvent) => {
    const clicked = DetectClickArea(containerRef, event.target);
    if (clicked && !expanded) {
      setExpanded(true);
    } else if(!clicked){
      setSearchTerm("");
      collapse();
    }
  }, [containerRef, setSearchTerm, expanded, setExpanded]);

  useEffect(() => {
    setSearchTerm("");
    document.addEventListener("mousedown", detectClickArea);
    return () => { document.removeEventListener("mousedown", detectClickArea); };
  }, [expanded, detectClickArea]);


  const selectedList = ():Identifier[] => {
    const castList:Identifier[] = selectedItemsList.map((item:ICheckbox) => {return {id:item.id, label:item.label}});
    const fullList:Identifier[] = props.selected.concat(castList);
    const nonDublicatedList: Identifier[] = fullList.filter((value:Identifier, index:number) => {
      const _value = JSON.stringify(value);
      return index === fullList.findIndex(obj => {
        return JSON.stringify(obj) === _value;
      });
    });

    return nonDublicatedList;
  }

  let chipElements = selectedList().map((item:Identifier) => props.singleSelect ?
    <div key={`div_${item.id}`} className={styles.singleSelect} >{item.label}</div> :
    <ChipAtom disabled={props.disabled} key={item.id} text={item.label!} onRemove={() => { onClick(item) }} />
  );

  const assignContainerRef = (currentlySelectedRef: HTMLInputElement) => {
    containerRef.current = currentlySelectedRef;
  };

  const showTextInput = (props.search === undefined || props.search) ;
  const showContent = !props.disabled;
  const showPlaceHolder = props.search === false && selectedList().length <= 0;
  const placeholder = (selectedList().length > 0) ? undefined : props.tekst.placeholder;
  const filteredOptions = filterBySearch(props.categories, searchTerm);
  const checkboxList = filteredOptions && filteredOptions.length > 0 && <CheckboxListMolecule list={filteredOptions} onClick={onClick} />

  return (
    <div className={styles.container}>
      <LabelMolecule id={props.tekst.id} label={props.tekst.label} tooltip={props.tekst.tooltip} required={props.required} />
      <div className={classNameInput} ref={assignContainerRef}>
        <div id={props.tekst.id} key={"input_" + props.tekst.label} className={classNameControls}>
          {chipElements}
          {showTextInput && <TextInputAtom
            inputRef={props.inputRef}
            onEscape={() => { 
              collapse();
            }}
            onBlur={() => setShowValidationMessage(true) }
            type={"text"}
            value={searchTerm}
            placeholder={placeholder}
            onChange={setSearchTerm}
            disabled={props.disabled}
          />}
          {showPlaceHolder && <p className={styles.nosearch}>{props.tekst.placeholder}</p>}
        </div>
        {showContent && <div key={"options_" + props.tekst.label} className={classNameResults}>{checkboxList ? checkboxList : "Geen resultaten"}</div>}
      </div>
      {showValidationError && <IconLabelMolecule icon={"icon-alert-red"} label={validationErrors[0]} type={"alert"} />}
    </div>
  );
}
