import { Identifier } from ".";

//DOCS: https://koop.gitlab.io/plooi/aanleveren/openapi-spec/#tag/Beschikbare-metadata-acties

export interface Titelcollectie {
  officieleTitel: string;
  verkorteTitels?: string[];
  alternatieveTitels?: string[];
}

export interface Classificatiecollectie {
  documentsoorten: Identifier[];
  themas?: Identifier[];
  onderwerpenRonl?: Identifier[];
}

export interface Documenthandelingen {
  soortHandeling: Identifier;
  atTime: string; //date-time
  wasAssociatedWith: Identifier;
}

export interface PlooiIntern {
  publicatiestatus: string, 
}

export interface Velden {
  key: string;
  values: string[];
}

export interface Bestand {
  bestandsnaam: string;
  label: string;
}

export interface Documentrelaties {
  //hoofddoc heeft geen relatie nog
  relation: string; //pid hoofddoc
  role: string; //waardelijst id (bijlage bij)
}

export interface Geldigheid {
  begindatum?: string; //date-time
  einddatum?: string; //date-time
}

export interface DocumentDetails {
  creatiedatum?: string;
  publisher: Identifier;
  verantwoordelijke?: Identifier;
  medeverantwoordelijken?: Identifier[];
  opsteller?: Identifier;
  naamOpsteller?: string;
  identifiers: string[];
  language: Identifier;
  titelcollectie: Titelcollectie;
  omschrijvingen?: string[];
  classificatiecollectie: Classificatiecollectie;
  format?: Identifier;
  onderwerpen?: string[];
  aggregatiekenmerk?: string;
  documenthandelingen: Documenthandelingen[];
  geldigheid?: Geldigheid;
  pid?: string;
  publicatiebestemming: string[];
  zichtbaarheidsdatumtijd?: string;
}

export interface Versies {
  mutatiedatumtijd?: string;
  openbaarmakingsdatum?: string;
  bestanden?: Bestand[];
}

export interface Metadata {
  document: DocumentDetails;
  documentrelaties?: Documentrelaties[];
  plooiIntern?: PlooiIntern;
  versies?: Versies[];
}

// Bij de search is ander response dan bij de metadata
export const PUBLICATIE_STATUS = {
  uitgesteld: "latere-publicatie",
  gepubliceerd: "gepubliceerd",
  ingetrokken: "intrekking",
  virusscanner: "virusscanner",
  virus: "virus",
  mappingfouten: "mappingfouten"
};
