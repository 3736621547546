import React, { useEffect, useState } from 'react';
import { RedocStandalone } from 'redoc';
import { FlexLayout } from "../../layouts";
import { OverheidHeaderOrganism, ToastOrganism } from "../../organisms";

const useSpec = (url) => {
    const [spec, setSpec] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchSpec = async () => {
            try {
                const response = await fetch(url);
                if (!response.ok) throw new Error(`Failed to fetch spec: ${response.status} ${response.statusText}`);
                const jsonSpec = await response.json();
                setSpec(jsonSpec);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchSpec();
    }, [url]);

    return { spec, loading, error };
};

const RedocScreen = () => {
    const { spec, loading, error } = useSpec(process.env.REACT_APP_API_DOC);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        console.error('Error loading OpenAPI spec:', error);
        return <div>Error loading OpenAPI spec: {error}</div>;
    }

    const options = {
        hideHostname: true,
    };

    return (
        <FlexLayout
            header={
                <>
                    <ToastOrganism />
                    <OverheidHeaderOrganism />
                </>
            }
            body={
                <RedocStandalone
                    spec={spec}
                    options={options}
                />
            }
        />
    );
};

export default RedocScreen;
