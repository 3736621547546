import { FileUploader } from "react-drag-drop-files";
import styles from "./styles.module.scss";

interface Props {
  children: any;
  onFileChanged: (file?: File) => any;
  onClickFileInput?: (file?: File | string) => any;
  accept?: string[];
  disabled?: boolean
  inputRef?: (ref: HTMLDivElement) => void;
  selectedInput?: File | string;
}

export function FileInputAtom(props: Props) {
  if(props.disabled) {
    return props.children;
  }

  return <div role={"input"} className={styles.file_input__container} ref={(ref:HTMLDivElement) => {props.inputRef && ref && props.inputRef(ref); }} onClick={()=>{if(props.onClickFileInput) props.onClickFileInput(props.selectedInput);}}>
    <FileUploader multiple={false} classes={styles.file_input__container} types={props.accept} handleChange={(file:File|undefined)=>props.onFileChanged(file)}>
      {
        props.children
      }
    </FileUploader>
  </div>
}
